<template>
    <v-stepper-content :step="step">
        予測処理が完了するまでしばらくお待ち下さい
        <v-card flat class="mx-auto text-center mt-10" width="800">
            <v-progress-linear
                color="primary"
                height="15"
                :value="progressValue"
                >
                <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>
            <br>
            {{ statusList[status] }}
        </v-card>
        <!--<v-btn @click="updateCurrentStatus();">次のステータスへ（テスト用）</v-btn>-->
    </v-stepper-content>
</template>

<script>
export default {
    data: () => {
        const statusList = {
            200: "予測用週次発注データ作成完了",
            210: "前処理（統計情報計算等）中",
            220: "需要予測期間=1週間　処理中（モデル：c0）",
            221: "需要予測期間=1週間　処理中（モデル：c1）",
            222: "需要予測期間=1週間　処理中（モデル：b0）",
            223: "需要予測期間=1週間　処理中（モデル：b1）",
            230: "需要予測期間=2週間　処理中（モデル：c0）",
            231: "需要予測期間=2週間　処理中（モデル：c1）",
            232: "需要予測期間=2週間　処理中（モデル：b0）",
            233: "需要予測期間=2週間　処理中（モデル：b1）",
            240: "需要予測期間=4週間　処理中（モデル：c0）",
            241: "需要予測期間=4週間　処理中（モデル：c1）",
            242: "需要予測期間=4週間　処理中（モデル：b0）",
            243: "需要予測期間=4週間　処理中（モデル：b1）",
            250: "需要予測期間=8週間　処理中（モデル：c0）",
            251: "需要予測期間=8週間　処理中（モデル：c1）",
            252: "需要予測期間=8週間　処理中（モデル：b0）",
            253: "需要予測期間=8週間　処理中（モデル：b1）",
            260: "需要予測期間=12週間　処理中（モデル：c0）",
            261: "需要予測期間=12週間　処理中（モデル：c1）",
            262: "需要予測期間=12週間　処理中（モデル：b0）",
            263: "需要予測期間=12週間　処理中（モデル：b1）",
            300: "需要予測処理完了",
        };
        const statusOrder = [ -1, ...Object.keys(statusList) ];
        return {
            statusOrder,
            statusList,
            eachProgressValue: 100/Object.keys(statusList).length,

            progressValue: 0,
        }
    },
    props: ["step", "status"],
    watch: {
        status(val) {
            this.progressValue = Math.ceil(this.statusOrder.indexOf(val)*this.eachProgressValue);
        }
    }
}
</script>
