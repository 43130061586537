<template>
    <v-card tile class="pa-3 ma-10">
        <v-overlay color="white" :opacity="0.6" absolute :value="overlay">
            <v-progress-circular color="grey" indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-card-title>
            需要予測を行う
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-stepper vertical v-model="currentStep" flat>

            <v-stepper-step step="1" :complete="currentStep>1">
                <div>
                    予測用データをアップロード
                    <v-btn
                        v-if="currentStep>1"
                        text
                        color="primary"
                        @click="goToFirstUpload()"
                        >
                        最初へ戻る
                    </v-btn>
                </div>
            </v-stepper-step>
            <first-upload-stepper-content
                :duct="duct"
                step="1"
                @complete="currentStep++;"
                />


            <v-stepper-step step="2" :complete="currentStep>2">
                需要予測処理
            </v-stepper-step>
            <forecast-stepper-content
                step="2"
                :status="status"
                @complete="currentStep++;"
                />


            <v-stepper-step step="3" :complete="currentStep>3">
                発注量計算用データをアップロード
            </v-stepper-step>
            <second-upload-stepper-content
                :duct="duct"
                step="3"
                @complete="currentStep++;"
                />


            <v-stepper-step step="4" :complete="currentStep>4">
                発注テーブルを参照
            </v-stepper-step>
            <v-stepper-content step="4">
                <div v-if="duct.STATUS && status==duct.STATUS.IN_POSTPROCESS">
                    データ作成中... 
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        />
                </div>
                <div v-if="duct.STATUS && status==duct.STATUS.FINISHED_POSTPROCESS">
                    データ作成が完了しました。<br><br>
                    <v-btn color="primary" to="/main/order/view">発注テーブルを確認</v-btn>
                </div>
            </v-stepper-content>

        </v-stepper>
    </v-card>
</template>

<script>
import FirstUploadStepperContent from './FirstUploadStepperContent'
import ForecastStepperContent from './ForecastStepperContent'
import SecondUploadStepperContent from './SecondUploadStepperContent'

export default {
    components: {
        FirstUploadStepperContent,
        ForecastStepperContent,
        SecondUploadStepperContent,
    },
    data: () => ({
        currentStep: -1,
        status: -1,
        overlay: true,
    }),
    props: ["duct"],
    methods: {
        goToFirstUpload() {
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.STATUS_SET, { status: this.duct.STATUS.IDLE })
        },
    },
    created() {
        this.duct.invokeOnOpen(() => {
            this.duct.setEventHandler(this.duct.EVENT.WATCH_STATUS, (rid,eid,status) => {
                this.status = status;

                if(status <= this.duct.STATUS.FINISHED_UPLOAD1)
                    this.currentStep = 1;
                else if(status < this.duct.STATUS.FINISHED_FORECAST)
                    this.currentStep = 2;
                else if(status < this.duct.STATUS.FINISHED_UPLOAD2)
                    this.currentStep = 3;
                else if(status <= this.duct.STATUS.FINISHED_POSTPROCESS)
                    this.currentStep = 4;

                this.overlay = false;
            });
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WATCH_STATUS);
        });
    }
}
</script>
