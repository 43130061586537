<template>
    <v-dialog
        v-model="dialog"
        max-width="700"
        >
        <unknown-codes-error-card 
            v-if="mode=='error-unknown-codes'"
            :details='details'
            @hide='hide'
        /> 
        <csv-error-card 
            v-else
            :details='details'
            :mode='mode'
            @hide='hide'
        />
    </v-dialog>
</template>
<script>
import UnknownCodesErrorCard from './UnknownCodesErrorCard.vue'
import CsvErrorCard from './CsvErrorCard'
export default {
    components:{
        UnknownCodesErrorCard,
        CsvErrorCard
    },
    data: () => ({
        dialog: false,
        details: {},
        mode: "",
    }),
    methods: {
        show(mode, details={}) {
            console.log(details);
            this.mode = mode;
            this.details = details;
            this.dialog = true;
        },
        hide() { this.dialog = false; },
    }
}
</script>
