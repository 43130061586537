<template>
    <v-stepper-content :step="step">
        <v-card-title class="text-body-1 ma-0 pa-0">
            対象週：
            <v-card flat width="200">
                <v-select v-model="selectedYW" :items="resultYWItems" />
                <!--<v-row>
                    <v-col cols="6">
                    <v-text-field
                        v-model.number="latestYW[0]"
                        type="number"
                        suffix="年"
                        />
                    </v-col>
                    <v-col cols="6">
                    <v-text-field
                        v-model.number="latestYW[1]"
                        type="number"
                        suffix="週"
                        />
                    </v-col>
                </v-row>-->
            </v-card>
        </v-card-title>
        <upload-form
            ref="form"
            mode="second"
            :duct="duct"
            :yw="selectedYW"
            />

        <second-upload-dialog
            ref="dialog"
            />
        <snackbar ref="snackbar" />
    </v-stepper-content>
</template>

<script>
import UploadForm from './UploadForm'
import SecondUploadDialog from './SecondUploadDialog'
import Snackbar from '@/components/ui/Snackbar'
export default {
    components: {
        UploadForm,
        SecondUploadDialog,
        Snackbar
    },
    data: () => ({
        resultYWs: [],
        selectedYW: [],
    }),
    props: ["duct", "step"],
    computed: {
        gotSameFilesOnInputChange() {
            for(const i in this.allFiles){
                if(this.currentFiles[i]!==this.allFiles[i]) {
                    return false;
                }
            }
            return this.currentFiles.length==this.allFiles.length;
        },
        resultYWItems() {
            return this.resultYWs.map((yw) => ({ text: `${yw[0]}年${yw[1]}週`, value: yw }));
        }
    },
    methods: {
    },
    mounted() {
        this.duct.invokeOnOpen(() => {
            console.log(this.duct.ERROR);
            this.duct.addEventHandler(this.duct.EVENT.FORECAST_UPLOAD_SECOND,
                {
                    error: (rid,eid,data) => {
                        console.log(data);
                        switch(data.error_code){
                            case this.duct.ERROR.FORECAST_UPLOAD_SECOND.REMAINS_ERROR:
                                this.$refs.dialog.show(
                                    "error-remains",
                                    data.details
                                );
                                break;
                            case this.duct.ERROR.FORECAST_UPLOAD_SECOND.STOCKS_ERROR:
                                this.$refs.dialog.show(
                                    "error-stocks",
                                    data.details
                                );
                                break;
                            case this.duct.ERROR.FORECAST_UPLOAD_SECOND.UNKNOWN_CODES:
                                this.$refs.dialog.show(
                                    "error-unknown-codes",
                                    data.details
                                );
                                break;
                            default:
                                console.error(data);
                                this.$refs.snackbar.show(
                                    "error",
                                    "システムエラーが発生しました。開発者に問い合わせて下さい。"
                                );
                                break;
                        }
                    },
                    complete: () => {
                        this.$refs.form.loading = false;
                    }
                }
            );
            this.duct.addEventHandler(
                this.duct.EVENT.WEEK_FORECAST_GET_FOR_RESULTS,
                { success: (rid,eid,data) => { this.resultYWs = data; } }
            );
            this.duct.addEventHandler(
                this.duct.EVENT.WEEK_FORECAST_GET_LAST,
                { success: (rid,eid,data) => { this.selectedYW = data; } }
            );
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WEEK_FORECAST_GET_FOR_RESULTS);
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WEEK_FORECAST_GET_LAST);
        });
    }
}
</script>
