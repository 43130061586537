<template>
    <v-card>
        <v-card-title>
            <v-icon color="success">mdi-check-circle</v-icon>データアップロードが完了しました
        </v-card-title>

        <v-card-text>
            以下のようなデータが含まれています：<br>
            <v-simple-table dense class="mt-2 mb-4">
                <tbody>
                    <tr>
                        <th style="width:25%;">実績データ週</th>
                        <td v-if="shippedYW==''">
                            <v-icon small color="warning">mdi-alert</v-icon> <b>データが見つかりません</b>
                        </td>
                        <td v-else>{{ shippedYW }}</td>
                    </tr>
                    <tr>
                        <th style="width:25%;">引当データ週</th>
                        <td v-if="orderYW==''">
                            <v-icon small color="warning">mdi-alert</v-icon> <b>データが見つかりません</b>
                        </td>
                        <td v-else>{{ orderYW }}</td>
                    </tr>
                    <tr>
                        <th style="width:25%;">shipments.csv 行数</th>
                        <td>{{ details["shipments_length"] }}</td>
                    </tr>
                    <tr>
                        <th style="width:25%;">products.csv 行数</th>
                        <td>{{ details["products_length"] }}</td>
                    </tr>
                    <tr>
                        <th style="width:25%;">customers.csv 行数</th>
                        <td>{{ details["customers_length"] }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            以下の週における予測を開始します：<br>
            <v-simple-table dense class="mt-2 mb-4">
                <tbody>
                    <tr>
                        <th>{{ details["forecast_yw"] ? getWeekStr(details["forecast_yw"]) : "" }}</th>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="confirm()">開始</v-btn>
            <v-btn text color="grey darken-2" @click="hide()">キャンセル</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:['details'],
    computed: {
        shippedYW() {
            return this.details["shipment_yw_list"] ? this.details["shipment_yw_list"].map(this.getWeekStr).join(" / ") : "";
        },
        orderYW() {
            return this.details["order_yw_list"] ? this.details["order_yw_list"].map(this.getWeekStr).join(" / ") : "";
        },
    },
    methods:{
        confirm(){
            this.$emit('confirm');
        },
        hide(){
            this.$emit('hide');
        },
        getWeekStr: (p) => (`${p[0]}年${p[1]}週`),
    }
}
</script>
