<template>
    <v-stepper-content :step="step">
        <v-card-title class="text-body-1 ma-0 pa-0">
            予測週：
            <v-card flat width="200">
                <v-row>
                    <v-col cols="6">
                    <v-text-field
                        v-model.number="thisYW[0]"
                        type="number"
                        suffix="年"
                        />
                    </v-col>
                    <v-col cols="6">
                    <v-text-field
                        v-model.number="thisYW[1]"
                        type="number"
                        suffix="週"
                        />
                    </v-col>
                </v-row>
            </v-card>
        </v-card-title>
        <upload-form
            ref="form"
            mode="first"
            :yw="thisYW"
            :duct="duct"
            />
        <div class="mt-4">
            <v-btn
                v-if="latestYW && latestYW[0] && latestYW[1]"
                outlined
                color="primary"
                @click="skipForecast()"
                >前回（{{ latestYW[0] }}年{{ latestYW[1] }}週）のデータと予測結果を使う</v-btn>
        </div>
        <first-upload-dialog
            ref="dialog"
            @confirm="complete"
            />
        <snackbar ref="snackbar" />
    </v-stepper-content>
</template>

<script>
import UploadForm from './UploadForm'

import FirstUploadDialog from './FirstUploadDialog'
import Snackbar from '@/components/ui/Snackbar'
export default {
    components: {
        UploadForm,
        FirstUploadDialog,
        Snackbar
    },
    data: () => ({
        latestYW: null,
        thisYW: [null, null]
    }),
    props: ["duct", "step"],
    methods: {
        async complete() {
            console.log('complete');
            let data = null;
            try {
                data = await this.duct.call(this.duct.EVENT.FORECAST_UPLOAD_FIRST, true);
                console.log(data)
            } catch (e) {
                console.log(e);
            }
            if(data.success){
                await this.duct.send(this.duct.nextRid(), this.duct.EVENT.FORECAST_START_ML);
            }
        },
        skipForecast() {
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.SET_STATUS, { StatusValue: this.duct.STATUS.FINISHED_FORECAST })
        },
    },
    mounted() {
        this.duct.invokeOnOpen(async () => {
            this.duct.addEventHandler(
                this.duct.EVENT.WEEK_FORECAST_GET_FOR_LATEST_RESULT,
                { success: (rid,eid,data) => { this.latestYW = data; } }
            );

            this.duct.addEventHandler(
                this.duct.EVENT.FORECAST_UPLOAD_FIRST, 
                {
                    success: (rid,eid,data) => {
                        this.$refs.dialog.show(
                            "confirm",
                            data.summary
                        );
                    },
                    error: (rid,eid,data) => {
                        switch(data.error_code){
                            case this.duct.ERROR.FORECAST_UPLOAD_FIRST.PRODUCTS_ERROR:
                                this.$refs.dialog.show(
                                        "error-products",
                                        data.details
                                    );
                                break;
                            case this.duct.ERROR.FORECAST_UPLOAD_FIRST.SHIPMENTS_ERROR:
                                this.$refs.dialog.show(
                                        "error-shipments",
                                        data.details
                                    );
                                break;
                            case this.duct.ERROR.FORECAST_UPLOAD_FIRST.CUSTOMERS_ERROR:
                                console.log(data)
                                this.$refs.dialog.show(
                                        "error-customers",
                                        data.details
                                    );
                                break;
                            case this.duct.ERROR.FORECAST_UPLOAD_FIRST.UNKNOWN_CODES:
                                this.$refs.dialog.show(
                                        "error-unknown-codes",
                                        data.details
                                    );
                                break;
                            default:
                                console.error(data);
                                this.$refs.snackbar.show("error", "システムエラーが発生しました。開発者に問い合わせて下さい。");
                                break;
                        }
                    },
                    complete: () => {
                        this.$refs.form.loading = false;
                        this.$refs.form.uploadProgress = 0;
                    }
                }
            );
            this.duct.addEventHandler(this.duct.EVENT.WEEK_GET_FOR_NOW, { success: (rid,eid,data) => { this.thisYW = data; } });

            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WEEK_GET_FOR_NOW);
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WEEK_FORECAST_GET_FOR_LATEST_RESULT);
        });
    }
}
</script>
