<template>
    <v-card>
        <v-card-title>
            <v-icon color="error">mdi-alert</v-icon> マスタにないコードを含むデータが見つかりました
        </v-card-title>
        <v-card-text>
            <div v-for="key in Object.keys(details)" :key="`unknown-codes-${key}`">
                <div v-if="details[key].length > 0">
                    {{ subtitles[key] }}：
                    <v-textarea
                        readonly
                        :rows="Math.min(details[key].length, 5)"
                        :value="details[key].join('\n')"
                        />
                </div>
            </div>
        </v-card-text>

        <v-card-actions>
            <v-spacer />
            <v-btn text color="grey darken-2" @click="hide()">閉じる</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        subtitles: {
            product_codes: '商品コード',
            customer_codes: '顧客コード',
            stocks: '残在庫データに含まれる商品コード',
            remains: '発注残データに含まれる商品コード',
        }
    }),
    props:['details'],
    methods:{
        hide(){
            this.$emit('hide',false);
        }
    }
}
</script>
