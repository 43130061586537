<template>
    <v-dialog
        v-model="dialog"
        max-width='1200'
        >
        <csv-confirm-card 
            v-if="mode=='confirm'"
            :details='details'
            @confirm='confirm'
            @hide='hide'
        />
        <unknown-codes-error-card 
            v-else-if="mode=='error-unknown-codes'"
            :details='details'
            @hide='hide'
        />
        <csv-error-card 
            v-else-if="['error-products','error-shipments','error-customers'].includes(mode)"
            :details='details'
            :mode='mode'
            @hide='hide'
        />
    </v-dialog>
</template>

<script>
import CsvConfirmCard from './CsvConfirmCard'
import UnknownCodesErrorCard from './UnknownCodesErrorCard'
import CsvErrorCard from './CsvErrorCard'
export default {
    components:{
        CsvConfirmCard,
        UnknownCodesErrorCard,
        CsvErrorCard
    },
    data: () => ({
        dialog: false,
        details: {},
        mode: "",
    }),
    methods: {
        show(mode, details={}) {
            console.log(details);
            this.details = details;
            this.mode = mode;
            this.dialog = true;
        },
        hide() { this.dialog = false; },
        confirm() {
            this.$emit("confirm");
            this.hide();
        },
    },
}
</script>
