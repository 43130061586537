<template>
    <v-card>
        <v-card-title>
            <v-icon color="error">mdi-alert</v-icon> {{details.error_file}}に空欄または不正な値の入力が見つかりました
        </v-card-title>

        <v-card-text v-if="['error-remains','error-stocks','error-shipments','error-customers'].includes(mode)">
            <v-data-table 
                :headers='tableHeaders'
                :items='tableItems'
            >
                <!--<template v-slot:item.alerts="{ item }">
                    <v-chip
                        v-for="alert in item.alerts"
                        dark
                        :key="alert"
                        color="red"
                        outlined
                    >
                        <v-icon>{{alertTypes[alert].icon}}</v-icon>{{ alertTypes[alert].text }}
                    </v-chip>
                </template>-->
            </v-data-table>
        </v-card-text>
        <v-card-text v-else-if="mode=='error-products'">
            <v-data-table 
                :headers='tableHeaders'
                :items='tableItems'
            >
                <!--<template v-slot:item.alerts="{ item }">
                    <v-chip
                        v-for="alert in item.alerts"
                        dark
                        :key="alert"
                        color="red"
                        outlined
                    >
                        <v-icon>{{alertTypes[alert].icon}}</v-icon>{{ alertTypes[alert].text }}
                    </v-chip>
                </template>-->
                <template v-slot:item.forecast_period="{ item }">
                        <span v-if="item.alerts.includes('invalid_fp')" style='color:red'>{{ item.forecast_period }}</span>
                        <span v-else>{{ item.forecast_period }}</span>
                </template>
                <template v-slot:item.service_rate="{ item }">
                        <span v-if="item.alerts.includes('invalid_sr')" style='color:red'>{{ item.service_rate }}</span>
                        <span v-else>{{ item.service_rate }}</span>
                </template>
                <template v-slot:item.price="{ item }">
                        <span v-if="item.alerts.includes('price_empty')" style='color:red'>{{ item.price }}</span>
                        <span v-else>{{ item.price }}</span>
                </template>
                <template v-slot:item.case_quantity="{ item }">
                        <span v-if="item.alerts.includes('quantities_null')" style='color:red'>{{ item.case_quantity }}</span>
                        <span v-else>{{ item.case_quantity }}</span>
                </template>
                <template v-slot:item.box_quantity="{ item }">
                        <span v-if="item.alerts.includes('quantities_null')" style='color:red'>{{ item.box_quantity }}</span>
                        <span v-else>{{ item.box_quantity }}</span>
                </template>
            </v-data-table>
       </v-card-text> 

        <v-card-actions>
            <v-spacer />
            <v-btn text color="grey darken-2" @click="hide()">閉じる</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props:['details','mode'],
    computed:{
        alertTypes(){
            let _alerts = {};
            if(['error-remains','error-stocks','error-shipments'].includes(this.mode)){
                _alerts = {
                    'null_records':{ text:'空欄', icon:'mdi-crop-free'},
                    'string':{ text:'文字列', icon:'mdi-alphabetical-variant'},
                    'decimals':{ text:'小数', icon:'mdi-decimal-increase'},
                };
            }else if(this.mode == 'error-products'){
                _alerts = {
                     'invalid_fp':  { text:'予測週数', icon:'mdi-calendar' }, 
                     'invalid_sr':  { text:'安全率',icon:'mdi-stack-overflow' }, 
                     'price_empty': { text:'商品単価',icon:'mdi-currency-cny'}, 
                     'quantities_null': { text:'入数',icon:'mdi-layers-triple' }
                };
            }else if(this.mode == 'error-customers'){
                _alerts = {
                    'null_records':{ text:'空欄', icon:'mdi-crop-free'},
                };
            }
            return _alerts
        },
        tableHeaders(){
            let _headers = [];
            if(['error-remains','error-stocks'].includes(this.mode)){
                _headers = [
                    //{ text:'アラート', value:'alerts'},
                    { text:'商品コード', value:'code'},
                    { text:'入力値', value:'input_value'},
                ];
            }else if(this.mode == 'error-products'){
                _headers = [
                    //{ text: 'アラート', value: 'alerts' },
                    { text: '商品コード', value: 'code' },
                    { text: '予測週数', value: 'forecast_period' },
                    { text: '安全率', value: 'service_rate' },
                    { text: '単価', value: 'price' },
                    { text: 'ケース入数', value: 'case_quantity' },
                    { text: '箱入数', value: 'box_quantity' },
                ];
            }else if(this.mode == 'error-shipments'){
                _headers = [
                    //{ text:'アラート', value:'alerts'},
                    { text:'年', value:'year'},
                    { text:'週', value:'week'},
                    { text:'商品コード', value:'product_code'},
                    { text:'顧客コード', value:'customer_code'},
                    { text:'入力値', value:'input_value'},
                ];
            }else if(this.mode == 'error-customers'){
                _headers = [
                    //{ text:'アラート', value:'alerts'},
                    { text:'顧客コード', value:'code'},
                    { text:'顧客名', value:'customer_name'},
                ];
            }
            return _headers
        },
        tableItems(){
            let _items = [];
            const errorTypes = Object.keys(this.details.error_log[0]).filter(el => el != 'code');
            for(let _ind=0; _ind < this.details.products.length; _ind++){
                let _alerts = [];
                for(let _error of errorTypes){
                    if(this.details.error_log[_ind][_error]) _alerts.push(_error);
                }
                if(['error-remains','error-stocks'].includes(this.mode)){
                    _items.push({
                        alerts: _alerts,  
                        code:this.details.products[_ind].product_code,
                        input_value:this.details.products[_ind].value
                    });
                }else if(this.mode == 'error-products'){
                    _items.push({
                        alerts: _alerts,  
                        code: this.details.products[_ind].code,
                        forecast_period:this.details.products[_ind].forecast_period,
                        service_rate:this.details.products[_ind].service_rate,
                        price:this.details.products[_ind].price,
                        case_quantity:this.details.products[_ind].case_quantity,
                        box_quantity:this.details.products[_ind].box_quantity
                    });
                }else if(this.mode == 'error-shipments'){
                    _items.push({
                        alerts: _alerts,  
                        year: this.details.products[_ind].year,
                        week:this.details.products[_ind].week,
                        product_code:this.details.products[_ind].product_code,
                        customer_code:this.details.products[_ind].customer_code,
                        input_value:this.details.products[_ind].shipped_amount,
                    });
                }else if(this.mode == 'error-customers'){
                    _items.push({
                        alerts: _alerts,  
                        code: this.details.products[_ind].code,
                        customer_name:this.details.products[_ind].attr_customer_name,
                    });
                }
            }
            return _items
        }
    },
    methods:{
        hide(){
            this.$emit('hide');
        }
    }
}
</script>
